import React from "react"
import { Link, graphql } from "gatsby"
import Header from "../components/header/header"
import Footer from "../components/footer"
import image from "../../content/assets/team/img.jpg"
import { FaRegEnvelope } from "react-icons/fa"

const About = ({ data }) => {
  return (
    <div>
      <Header />
      <div className="bg-white pt-16 pb-16">
        <div className="max-w-4xl mx-auto">
          <div className="block md:flex shadow-2xl rounded-xl">
            <div className="w-100 md:w-1/2">
              <img
                src={image}
                className=" rounded-none md:rounded-bl-xl md:rounded-tl-xl object-cover h-full"
                alt="équipe"
              ></img>
            </div>
            <div className="w-100 md:w-1/2 p-8">
              <h1 className="text-center text-lg font-bold uppercase pb-6">
                Bonjour moi c'est Paul,
              </h1>
              <p className="text-justify">
                Artisan peintre basé à Toulouse, je suis spécialisé dans la
                rénovation de votre habitat. Avec des années d'expérience, je
                transforme vos espaces intérieurs grâce à des techniques de
                peinture professionnelles et des matériaux de haute qualité. Mon
                expertise en rénovation me permet de répondre aux besoins
                spécifiques de chaque projet, garantissant des résultats
                durables et esthétiques. N'attendez plus pour redonner vie à vos
                murs et plafonds.
              </p>
              <div className="flex justify-center pt-6">
                <Link
                  to="/contact"
                  className="w-fit shadow-md rounded-full bg-blue-900 text-slate-100 pb-2 pt-2 pr-4 pl-4 text-center  font-semibold flex items-center"
                >
                  <FaRegEnvelope />
                  <span className="pl-4">contact</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

// export const query = graphql`
// query {
//  assets: allFile(filter: {relativeDirectory: {eq: "assets/team"}}) {
// 		edges{
// 			node{
// 				publicURL
// 			}
// 		}
// 	}
// }
// `

export default About
